<template>
  <section class="supplier">
    <PageBlock></PageBlock>
    <div v-if="supplier.slug" :class="{ blurred: !$store.user.loggedIn }">
      <header
        v-if="supplier.coverPhoto"
        class="supplier__header"
        :style="{ backgroundImage: `url('${imagePath('images/' + supplier.coverPhoto, 1920, 500)}')` }"
        ref="header"
      ></header>
      <div class="supplier__container">
        <nav class="supplier__nav">
          <ul class="supplier__nav-list" ref="nav">
            <li class="supplier__nav-item">
              <a class="supplier__nav-link" href="#" v-scroll-to="{ el: '#overview', offset: -115 }" @click.prevent>
                <i class="supplier__nav-icon" v-html="svg.overviewIcon"></i>
                Overview
              </a>
            </li>
            <li class="supplier__nav-item">
              <a class="supplier__nav-link" href="#" v-scroll-to="{ el: '#production', offset: -90 }" @click.prevent>
                <i class="supplier__nav-icon" v-html="svg.productionIcon"></i>
                Production
              </a>
            </li>
            <li v-if="!companyInfoEmpty" class="supplier__nav-item">
              <a class="supplier__nav-link" href="#" v-scroll-to="{ el: '#info', offset: -90 }" @click.prevent>
                <i class="supplier__nav-icon" v-html="svg.companyIcon"></i>
                Company information
              </a>
            </li>
            <li v-if="customers.length" class="supplier__nav-item">
              <a class="supplier__nav-link" href="#" v-scroll-to="{ el: '#customers', offset: -90 }" @click.prevent>
                <i class="supplier__nav-icon" v-html="svg.customersIcon"></i>
                {{ supplier.category == 0 ? 'Component suppliers' : 'Footwear suppliers' }}
              </a>
            </li>
            <li v-if="supplier.gallery.length" class="supplier__nav-item">
              <a class="supplier__nav-link" href="#" v-scroll-to="{ el: '#gallery', offset: -90 }" @click.prevent>
                <i class="supplier__nav-icon" v-html="svg.galleryIcon"></i>
                Gallery
              </a>
            </li>
          </ul>
        </nav>
        <section class="supplier__info">
          <div class="supplier-content">
            <h1 class="supplier__name" id="overview">{{ supplier.title }}</h1>

            <div
              v-if="supplier.overview"
              class="supplier__overview"
              :class="{ 'supplier__overview--short': overviewShort }"
              ref="overview"
            >
              <div class="supplier__overview-text" v-html="supplier.overview"></div>
              <span class="supplier__overview-rm" @click="overviewShort = false">Read More</span>
            </div>

            <div class="supplier__contacts">
              <component
                v-if="supplier.coordinates && isMounted"
                :is="$store.user.chineese ? 'baidu-map' : 'google-map'"
                :supplier="supplier"
              ></component>
              <div class="supplier__contacts-info">
                <em v-if="supplier.fullAddress" class="supplier__address">{{ supplier.fullAddress }}</em>
                <ul class="supplier__contacts-list">
                  <li v-if="supplier.web" class="supplier__contacts-item">
                    <a
                      :href="supplier.web | urlAddProtocol"
                      target="_blank"
                      class="supplier__contacts-link"
                      @click="$ga.event('contacts', 'homepage click')"
                    >
                      <i class="supplier__contacts-icon" v-html="svg.siteIcon"></i>
                      {{ supplier.web | urlHideProtocol }}
                    </a>
                  </li>
                  <li v-if="supplier.email" class="supplier__contacts-item">
                    <a
                      :href="'mailto:' + supplier.email"
                      class="supplier__contacts-link"
                      @click="$ga.event('contacts', 'email click')"
                    >
                      <i class="supplier__contacts-icon" v-html="svg.emailIcon"></i>
                      {{ supplier.email }}
                    </a>
                  </li>
                  <li v-if="supplier.phone" class="supplier__contacts-item">
                    <a
                      :href="'tel:' + supplier.phone"
                      class="supplier__contacts-link"
                      @click="$ga.event('contacts', 'phone click')"
                    >
                      <i class="supplier__contacts-icon" v-html="svg.phoneIcon"></i>
                      {{ supplier.phone }}
                    </a>
                  </li>
                  <li v-if="supplier.whatsApp" class="supplier__contacts-item">
                    <span class="supplier__contacts-link" @click="$ga.event('contacts', 'WhatsApp click')">
                      <i class="supplier__contacts-icon" v-html="svg.whatsApp"></i>
                      {{ supplier.whatsApp }}
                    </span>
                  </li>
                  <li v-if="supplier.weChat" class="supplier__contacts-item">
                    <span class="supplier__contacts-link" @click="$ga.event('contacts', 'WeChat click')">
                      <i class="supplier__contacts-icon" v-html="svg.weChat"></i>
                      {{ supplier.weChat }}
                    </span>
                  </li>
                  <li v-if="supplier.skype" class="supplier__contacts-item">
                    <span class="supplier__contacts-link" @click="$ga.event('contacts', 'Skype click')">
                      <i class="supplier__contacts-icon" v-html="svg.skype"></i>
                      {{ supplier.skype }}
                    </span>
                  </li>
                </ul>
                <ul v-if="social" class="supplier__social">
                  <li v-if="supplier.linkedIn" class="supplier__social-item">
                    <a
                      :href="supplier.linkedIn | urlAddProtocol"
                      @click="$ga.event('contacts', 'linkedIn click')"
                      target="_blank"
                      class="supplier__social-link"
                      v-html="svg.linkedIn"
                    ></a>
                  </li>
                  <li v-if="supplier.facebook" class="supplier__social-item">
                    <a
                      :href="supplier.facebook | urlAddProtocol"
                      @click="$ga.event('contacts', 'facebook click')"
                      target="_blank"
                      class="supplier__social-link"
                      v-html="svg.facebook"
                    ></a>
                  </li>
                  <li v-if="supplier.instagram" class="supplier__social-item">
                    <a
                      :href="supplier.instagram | urlAddProtocol"
                      @click="$ga.event('contacts', 'instagram click')"
                      target="_blank"
                      class="supplier__social-link"
                      v-html="svg.instagram"
                    ></a>
                  </li>
                  <li v-if="supplier.pinterest" class="supplier__social-item">
                    <a
                      :href="supplier.pinterest | urlAddProtocol"
                      @click="$ga.event('contacts', 'pinterest click')"
                      target="_blank"
                      class="supplier__social-link"
                      v-html="svg.pinterest"
                    ></a>
                  </li>
                  <li v-if="supplier.twitter" class="supplier__social-item">
                    <a
                      :href="supplier.twitter | urlAddProtocol"
                      @click="$ga.event('contacts', 'twitter click')"
                      target="_blank"
                      class="supplier__social-link"
                      v-html="svg.twitter"
                    ></a>
                  </li>
                  <li v-if="supplier.googlePlus" class="supplier__social-item">
                    <a
                      :href="supplier.googlePlus | urlAddProtocol"
                      @click="$ga.event('contacts', 'google+ click')"
                      target="_blank"
                      class="supplier__social-link"
                      v-html="svg.googlePlus"
                    ></a>
                  </li>
                  <li v-if="supplier.youTube" class="supplier__social-item">
                    <a
                      :href="supplier.youTube | urlAddProtocol"
                      @click="$ga.event('contacts', 'youtube click')"
                      target="_blank"
                      class="supplier__social-link"
                      v-html="svg.youtube"
                    ></a>
                  </li>
                </ul>
                <a
                  v-if="supplier.apiccapsId"
                  class="supplier__association supplier__association--appicaps"
                  href="https://www.apiccaps.pt"
                  target="_blank"
                  rel="noopener noreferrer"
                  @click="$ga.event('partners', 'APICCAPS click - supplier page')"
                >
                  <img
                    src="../../img/partner-logos/apiccaps.png"
                    class="supplier__association-image"
                    alt="APICCAPS member"
                  />
                </a>
                <a
                  v-if="supplier.ciceg"
                  class="supplier__association supplier__association--ciceg"
                  href="https://www.ciceg.org"
                  target="_blank"
                  rel="noopener noreferrer"
                  @click="$ga.event('partners', 'CICEG click - supplier page')"
                >
                  <img src="../../img/partner-logos/ciceg.png" class="supplier__association-image" alt="CICEG member" />
                </a>
              </div>
            </div>

            <h2 id="production">Production</h2>

            <div class="supplier-content__data">
              <div v-if="supplier.companyStructure !== null" class="supplier-content__data-label">
                Company Structure
              </div>
              <div v-if="supplier.companyStructure !== null" class="supplier-content__data-content">
                {{ supplier.companyStructure == 0 ? 'Manufacturer' : 'Agent' }}
              </div>
              <div v-if="supplier.category !== null" class="supplier-content__data-label">Producing</div>
              <div v-if="supplier.category !== null" class="supplier-content__data-content">
                {{ supplier.category == 0 ? 'Footwear' : 'Components' }}
              </div>
            </div>
            <div v-if="supplier.category == 0" class="supplier-content__data">
              <div v-if="supplier.footwearTypes && supplier.footwearTypes.length" class="supplier-content__data-label">
                Footwear Types
              </div>
              <div
                v-if="supplier.footwearTypes && supplier.footwearTypes.length"
                class="supplier-content__data-content"
              >
                <span v-for="(article, i) in supplier.footwearTypes" :key="i">
                  <router-link
                    :to="{ name: 'article', params: { category: article.categorySlug, slug: article.slug } }"
                  >
                    {{ article.title }}
                  </router-link>
                  <span v-if="i + 1 < supplier.footwearTypes.length">, </span>
                </span>
              </div>

              <div v-if="supplier.processes && supplier.processes.length" class="supplier-content__data-label">
                Process
              </div>
              <div v-if="supplier.processes && supplier.processes.length" class="supplier-content__data-content">
                <span v-for="(article, i) in supplier.processes" :key="i">
                  <router-link
                    :to="{ name: 'article', params: { category: article.categorySlug, slug: article.slug } }"
                  >
                    {{ article.title }} </router-link
                  ><span v-if="i + 1 < supplier.processes.length">, </span>
                </span>
              </div>

              <div v-if="supplier.materials && supplier.materials.length" class="supplier-content__data-label">
                Materials
              </div>
              <div v-if="supplier.materials && supplier.materials.length" class="supplier-content__data-content">
                <span v-for="(article, i) in supplier.materials" :key="i">
                  <router-link
                    :to="{ name: 'article', params: { category: article.categorySlug, slug: article.slug } }"
                  >
                    {{ article.title }} </router-link
                  ><span v-if="i + 1 < supplier.materials.length">, </span>
                </span>
              </div>

              <div v-if="gender.length" class="supplier-content__data-label">Gender</div>
              <div v-if="gender.length" class="supplier-content__data-content">{{ gender }}</div>

              <div
                v-if="supplier.lastingMethods && supplier.lastingMethods.length"
                class="supplier-content__data-label"
              >
                Lasting Method
              </div>
              <div
                v-if="supplier.lastingMethods && supplier.lastingMethods.length"
                class="supplier-content__data-content"
              >
                <span v-for="(item, i) in supplier.lastingMethods" :key="i">
                  {{ item.title }}
                  <span v-if="i + 1 < supplier.lastingMethods.length">, </span>
                </span>
              </div>

              <div v-if="supplier.niche && supplier.niche.length" class="supplier-content__data-label">Niche</div>
              <div v-if="supplier.niche && supplier.niche.length" class="supplier-content__data-content">
                <span v-for="(item, i) in supplier.niche" :key="i">
                  {{ item.title }}<span v-if="i + 1 < supplier.niche.length">, </span>
                </span>
              </div>

              <div v-if="supplier.capabilities && supplier.capabilities.length" class="supplier-content__data-label">
                Capabilities
              </div>
              <div
                v-if="supplier.capabilities && supplier.capabilities.length"
                class="supplier-content__data-content supplier__grid supplier__grid"
              >
                <div
                  v-if="i == 0 || item.category != supplier.capabilities[i - 1].category"
                  class="supplier__grid-group"
                  v-for="(item, i) in supplier.capabilities"
                  :key="i"
                >
                  <div class="supplier__grid-group-label">{{ item.category }}</div>
                  <div class="supplier__grid-group-list">
                    <div
                      class="supplier__grid-item supplier__grid-item--large"
                      v-for="(item, i) in supplier.capabilities.filter((i) => i.category == item.category)"
                      :key="i"
                    >
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="supplier.category == 1 && showTypeOfProduction" class="supplier-content__data">
              <div class="supplier-content__data-label">Type of production</div>
              <div class="supplier-content__data-content">
                <div
                  v-if="item.options.some((el) => supplier[el.key])"
                  class="cstop"
                  v-for="(item, i) in $store.suppliers.typeOfProduction"
                  :key="i"
                >
                  <div class="cstop__label">{{ item.title }}</div>
                  <div v-if="supplier[category.key]" class="cstop__item" v-for="(category, i) in item.options" :key="i">
                    <span>{{ category.title }}</span>
                    <div class="cstop__grid">
                      <div
                        v-if="group.options.some((el) => supplier[el.key])"
                        v-for="(group, i) in category.options"
                        :key="i"
                      >
                        <div class="cstop__label">{{ group.groupTitle }}</div>
                        <div class="supplier__grid-group-list">
                          <div
                            v-if="supplier[option.key]"
                            :class="`supplier__grid-item supplier__grid-item--${group.gridSize}`"
                            v-for="(option, i) in group.options"
                            :key="i"
                          >
                            <span>{{ option.title }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h2 v-if="!companyInfoEmpty" id="info">Company information</h2>
            <div v-if="!companyInfoEmpty" class="supplier-content__data">
              <div v-if="supplier.founded" class="supplier-content__data-label">Founded</div>
              <div v-if="supplier.founded" class="supplier-content__data-content">{{ supplier.founded }}</div>

              <div v-if="supplier.employees" class="supplier-content__data-label">Employees</div>
              <div v-if="supplier.employees" class="supplier-content__data-content">{{ supplier.employees }}</div>

              <div v-if="supplier.moq" class="supplier-content__data-label">Minimum Order Quantity</div>
              <div v-if="supplier.moq" class="supplier-content__data-content">{{ supplier.moq }}</div>

              <div
                v-if="supplier.dailyProductionFrom && supplier.dailyProductionTo"
                class="supplier-content__data-label"
              >
                Daily Production
              </div>
              <div
                v-if="supplier.dailyProductionFrom && supplier.dailyProductionTo"
                class="supplier-content__data-content"
              >
                {{ supplier.dailyProductionFrom }} - {{ supplier.dailyProductionTo }}
              </div>

              <div v-if="supplier.brands" class="supplier-content__data-label">Brands producing</div>
              <div v-if="supplier.brands" class="supplier-content__data-content">{{ supplier.brands }}</div>

              <div v-if="materialExpertise.length" class="supplier-content__data-label">Material Expertise</div>
              <div v-if="materialExpertise.length" class="supplier-content__data-content">{{ materialExpertise }}</div>

              <div v-if="supplier.sizeRangeFrom && supplier.sizeRangeFrom" class="supplier-content__data-label">
                Size Range producing
              </div>
              <div v-if="supplier.sizeRangeFrom && supplier.sizeRangeFrom" class="supplier-content__data-content">
                {{ supplier.sizeRangeFrom }} – {{ supplier.sizeRangeTo }}
              </div>
            </div>

            <h2 v-if="customers.length" id="customers">
              {{ supplier.category == 0 ? 'Component suppliers working with' : 'Footwear suppliers working with' }}
            </h2>
            <ul v-if="customers.length" class="supplier__customers">
              <li class="supplier__customer" v-for="(customer, i) in customers" :key="i">
                <router-link
                  class="supplier__customer-link"
                  :to="{ name: 'supplier', params: { name: customer.slug } }"
                >
                  <div
                    class="supplier__customer-img"
                    :style="{ backgroundImage: `url('${(imagePath('images/' + customer.coverPhoto), 1920, 500)})` }"
                  ></div>
                  <div class="supplier__customer-info">
                    <strong class="supplier__customer-title">
                      {{ customer.name }}
                    </strong>
                  </div>
                </router-link>
              </li>
            </ul>

            <div v-if="supplier.gallery.length" class="supplier__gallery">
              <h2 id="gallery">Gallery</h2>
              <div v-for="(item, i) in supplier.gallery" class="supplier__gallery-item" :key="i">
                <img
                  v-if="item.type == 'img'"
                  :src="`${$blobUrl}/images/${item.url}`"
                  class="supplier__gallery-img"
                  alt=""
                />
                <div v-if="item.type == 'video'" class="supplier__gallery-video">
                  <iframe :src="item.url" frameborder="0"></iframe>
                </div>
                <div v-if="item.caption.length" class="supplier__gallery-caption">{{ item.caption }}</div>
              </div>
            </div>
          </div>

          <router-link
            v-if="showEditSupplierButton"
            :to="{ name: 'supplierEdit', params: { id: supplier.id } }"
            class="supplier-user-button"
          >
            Edit supplier
          </router-link>
          <a :href="`mailto:${supplier.email}`" class="supplier-user-button" v-else> Contact supplier </a>
          <span v-if="showClaimSupplierButton" class="supplier-user-button" @click="claimSupplier">Claim Supplier</span>
        </section>
      </div>
    </div>
    <div class="post-content" v-else>
      <p>Page not found</p>
    </div>
  </section>
</template>

<script>
const BaiduMap = () => import('./old/baidu-map.vue');
const GoogleMap = () => import('./old/google-map.vue');

import { imagePath } from '@lib/imgproxy';

import PageBlock from './../components/page-block.vue';

// SVG
import overviewIcon from '../../img/home-icon.svg';
import productionIcon from '../../img/build-icon.svg';
import companyIcon from '../../img/domain-icon.svg';
import customersIcon from '../../img/customers-icon.svg';
import galleryIcon from '../../img/photo-icon.svg';
import siteIcon from '../../img/public-icon.svg';
import emailIcon from '../../img/email-icon.svg';
import phoneIcon from '../../img/phone-icon.svg';
import whatsApp from '../../img/whatsapp-icon.svg';
import weChat from '../../img/wechat-icon.svg';
import skype from '../../img/skype-icon.svg';
import facebook from '../../img/facebook-s-icon.svg';
import linkedIn from '../../img/linkedin-s-icon.svg';
import twitter from '../../img/twitter-s-icon.svg';
import instagram from '../../img/instagram-s-icon.svg';
import pinterest from '../../img/pinterest-s-icon.svg';
import googlePlus from '../../img/gplus-s-icon.svg';
import youtube from '../../img/youtube-s-icon.svg';

export default {
  load({ store, route, router }) {
    if (
      [
        'design-shoes-colection-srl',
        'valuni--eurodavil',
        'okabashi-brands',
        'texon',
        'pittards-plc',
        't-k-shoes-factory',
        'yi-sheng-leather-co-ltd',
        'litian-textile-technology-co-ltd',
        'viet-star-viet-nam-co-ltd',
        'freecode-technology-coltd',
        'hong-chen-vietnam-co-ltd',
        'yin-hwa-precision-lasts-co-ltd',
      ].includes(route.params.name)
    ) {
      router.replace({ name: 'supplierProfile', params: { name: route.params.name } });
    }

    return store.suppliers.loadBySlug(route.params.name).then((data) => {
      store.head.title = `FindSourcing – ${data.title}`;
      // TODO: Add custom head.description
      store.head.image = data.coverPhoto;
    });
  },
  components: { PageBlock, BaiduMap, GoogleMap },
  filters: {
    // TODO: Use lib/formatters instead where these two now exist
    urlAddProtocol: (value) => {
      const pattern = /^((http|https):\/\/)/;
      return !pattern.test(value) ? 'http://' + value : value;
    },
    urlHideProtocol: (value) => {
      return value.replace(/^(https?:|)\/\//, '');
    },
  },
  data() {
    return {
      navigationIsFixed: false,
      overviewShort: true,

      svg: {
        overviewIcon,
        productionIcon,
        companyIcon,
        customersIcon,
        galleryIcon,
        siteIcon,
        emailIcon,
        phoneIcon,
        whatsApp,
        weChat,
        skype,
        facebook,
        linkedIn,
        twitter,
        instagram,
        pinterest,
        googlePlus,
        youtube,
      },
      isMounted: false,
    };
  },
  computed: {
    supplier() {
      return this.$store.suppliers.all[this.$route.params.name] || {};
    },
    gender() {
      let gender = [];
      if (this.supplier.men) gender.push('Men');
      if (this.supplier.ladies) gender.push('Ladies');
      if (this.supplier.kids) gender.push('Boys & Girls');
      if (this.supplier.babies) gender.push('Babies');
      return gender.join(', ');
    },
    materialExpertise() {
      let me = [];
      if (this.supplier.materialExpertiseLeather) me.push('Leather');
      if (this.supplier.materialExpertiseTextile) me.push('Textile');
      if (this.supplier.materialExpertiseSynthetic) me.push('Synthetic');
      return me.join(', ');
    },
    social() {
      return (
        !!this.supplier.facebook ||
        !!this.supplier.twitter ||
        !!this.supplier.linkedIn ||
        !!this.supplier.pinterest ||
        !!this.supplier.instagram ||
        !!this.supplier.youTube
      );
    },
    showEditSupplierButton() {
      // Supplier found
      if (this.supplier.status == 0) return false;
      // User is admin, or
      if (this.$store.user.isAdmin) return true;
      // User is connected to supplier
      return this.$store.user.hasCompany(this.supplier.id);
    },
    showClaimSupplierButton() {
      // Supplier is found
      if (this.supplier.status == 0) return false;
      // User is logged in
      if (!this.$store.user.loggedIn) return false;
      // User is not admin
      if (this.$store.user.isAdmin) return false;
      // Company is not already connected to any user
      return this.supplier.users.length === 0;
    },
    companyInfoEmpty() {
      return (
        !this.supplier.founded &&
        !this.supplier.employees &&
        !this.supplier.lineWorkers &&
        !this.supplier.factories &&
        !this.supplier.moq &&
        !this.supplier.dailyProductionFrom &&
        !this.supplier.dailyProductionTo &&
        !this.materialExpertise &&
        !this.supplier.brands &&
        !this.supplier.externalBrands &&
        !this.supplier.sizeRangeFrom &&
        !this.supplier.sizeRangeTo
      );
    },
    navigation() {
      return this.$refs['nav'];
    },
    customers() {
      return this.supplier.category == 0
        ? this.supplier.suppliers.filter((c) => !c.draft)
        : this.supplier.customers.filter((c) => !c.draft);
    },
    showTypeOfProduction() {
      return (
        this.supplier.materialLeather ||
        this.supplier.materialTextile ||
        this.supplier.materialSynthetic ||
        this.supplier.trimsMetal ||
        this.supplier.trimsShoelaces ||
        this.supplier.trimsToePuffsStiffners ||
        this.supplier.trimsInsoles ||
        this.supplier.solesHeelsAndRelatedMidsoles ||
        this.supplier.solesHeelsAndRelatedOutsoles ||
        this.supplier.solesHeelsAndRelatedHeels ||
        this.supplier.solesHeelsAndRelatedWelts ||
        this.supplier.lastsAndRelatedLasts ||
        this.supplier.lastsAndRelatedShanks ||
        this.supplier.logisticStickers ||
        this.supplier.logisticPackaging
      );
    },
  },
  methods: {
    imagePath,
    initNav() {
      this.navigation.classList.remove('supplier__nav-list--fixed');
      this.navigationIsFixed = false;
      this.toggleNav();
    },
    toggleNav() {
      let headerHeight = this.$refs['header'] ? this.$refs['header'].clientHeight : 0;
      let breakPoint = headerHeight + 65;
      if (this.navigationIsFixed && window.pageYOffset <= breakPoint) {
        this.navigationIsFixed = false;
        this.navigation.classList.remove('supplier__nav-list--fixed');
      } else if (!this.navigationIsFixed && window.pageYOffset > breakPoint) {
        this.navigationIsFixed = true;
        this.navigation.classList.add('supplier__nav-list--fixed');
      }
    },
    cropOverview() {
      let os = this.$refs.overview;
      if (os && os.clientHeight > 300 && !os.classList.contains('is-cropped')) {
        os.classList.add('is-cropped');
      }
    },

    initPage() {
      if (this.supplier.title) {
        this.cropOverview();
        this.initNav();
        window.addEventListener('resize', this.initNav);
        window.addEventListener('scroll', this.toggleNav);
        window.addEventListener('resize', this.cropOverview);
      }
    },
    async claimSupplier() {
      const { success } = await this.$store.suppliers.claimSupplier(
        this.$store.user.current.userName,
        this.supplier.title
      );

      if (success) {
        this.$notify({
          type: 'success',
          title: 'Your request was sent successfully',
        });
      } else {
        this.$notify({
          type: 'error',
          title: 'Something went wrong',
        });
      }
    },
  },
  mounted() {
    this.initPage();
    this.isMounted = true;
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.initNav);
    window.removeEventListener('scroll', this.toggleNav);
    window.removeEventListener('resize', this.cropOverview);
  },
  watch: {
    '$route.fullPath'() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

.blurred {
  filter: blur(3px);
}

.supplier {
  &__association {
    display: inline-flex;
    height: math.div(7.5rem, 1.6);
    margin-right: math.div(2rem, 1.6);
    object-fit: contain;

    &--appicaps {
      width: math.div(7.5rem, 1.6);
    }
    &--ciceg {
      width: math.div(21rem, 1.6);
    }
  }

  &__association-image {
  }
}
</style>
