var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "supplier" },
    [
      _c("PageBlock"),
      _vm._v(" "),
      _vm.supplier.slug
        ? _c("div", { class: { blurred: !_vm.$store.user.loggedIn } }, [
            _vm.supplier.coverPhoto
              ? _c("header", {
                  ref: "header",
                  staticClass: "supplier__header",
                  style: {
                    backgroundImage:
                      "url('" +
                      _vm.imagePath(
                        "images/" + _vm.supplier.coverPhoto,
                        1920,
                        500
                      ) +
                      "')",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "supplier__container" }, [
              _c("nav", { staticClass: "supplier__nav" }, [
                _c("ul", { ref: "nav", staticClass: "supplier__nav-list" }, [
                  _c("li", { staticClass: "supplier__nav-item" }, [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "scroll-to",
                            rawName: "v-scroll-to",
                            value: { el: "#overview", offset: -115 },
                            expression: "{ el: '#overview', offset: -115 }",
                          },
                        ],
                        staticClass: "supplier__nav-link",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "supplier__nav-icon",
                          domProps: { innerHTML: _vm._s(_vm.svg.overviewIcon) },
                        }),
                        _vm._v("\n              Overview\n            "),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "supplier__nav-item" }, [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "scroll-to",
                            rawName: "v-scroll-to",
                            value: { el: "#production", offset: -90 },
                            expression: "{ el: '#production', offset: -90 }",
                          },
                        ],
                        staticClass: "supplier__nav-link",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "supplier__nav-icon",
                          domProps: {
                            innerHTML: _vm._s(_vm.svg.productionIcon),
                          },
                        }),
                        _vm._v("\n              Production\n            "),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  !_vm.companyInfoEmpty
                    ? _c("li", { staticClass: "supplier__nav-item" }, [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "scroll-to",
                                rawName: "v-scroll-to",
                                value: { el: "#info", offset: -90 },
                                expression: "{ el: '#info', offset: -90 }",
                              },
                            ],
                            staticClass: "supplier__nav-link",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "supplier__nav-icon",
                              domProps: {
                                innerHTML: _vm._s(_vm.svg.companyIcon),
                              },
                            }),
                            _vm._v(
                              "\n              Company information\n            "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.customers.length
                    ? _c("li", { staticClass: "supplier__nav-item" }, [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "scroll-to",
                                rawName: "v-scroll-to",
                                value: { el: "#customers", offset: -90 },
                                expression: "{ el: '#customers', offset: -90 }",
                              },
                            ],
                            staticClass: "supplier__nav-link",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "supplier__nav-icon",
                              domProps: {
                                innerHTML: _vm._s(_vm.svg.customersIcon),
                              },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.supplier.category == 0
                                    ? "Component suppliers"
                                    : "Footwear suppliers"
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.supplier.gallery.length
                    ? _c("li", { staticClass: "supplier__nav-item" }, [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "scroll-to",
                                rawName: "v-scroll-to",
                                value: { el: "#gallery", offset: -90 },
                                expression: "{ el: '#gallery', offset: -90 }",
                              },
                            ],
                            staticClass: "supplier__nav-link",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "supplier__nav-icon",
                              domProps: {
                                innerHTML: _vm._s(_vm.svg.galleryIcon),
                              },
                            }),
                            _vm._v("\n              Gallery\n            "),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "section",
                { staticClass: "supplier__info" },
                [
                  _c("div", { staticClass: "supplier-content" }, [
                    _c(
                      "h1",
                      {
                        staticClass: "supplier__name",
                        attrs: { id: "overview" },
                      },
                      [_vm._v(_vm._s(_vm.supplier.title))]
                    ),
                    _vm._v(" "),
                    _vm.supplier.overview
                      ? _c(
                          "div",
                          {
                            ref: "overview",
                            staticClass: "supplier__overview",
                            class: {
                              "supplier__overview--short": _vm.overviewShort,
                            },
                          },
                          [
                            _c("div", {
                              staticClass: "supplier__overview-text",
                              domProps: {
                                innerHTML: _vm._s(_vm.supplier.overview),
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "supplier__overview-rm",
                                on: {
                                  click: function ($event) {
                                    _vm.overviewShort = false
                                  },
                                },
                              },
                              [_vm._v("Read More")]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "supplier__contacts" },
                      [
                        _vm.supplier.coordinates && _vm.isMounted
                          ? _c(
                              _vm.$store.user.chineese
                                ? "baidu-map"
                                : "google-map",
                              {
                                tag: "component",
                                attrs: { supplier: _vm.supplier },
                              }
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "supplier__contacts-info" }, [
                          _vm.supplier.fullAddress
                            ? _c("em", { staticClass: "supplier__address" }, [
                                _vm._v(_vm._s(_vm.supplier.fullAddress)),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("ul", { staticClass: "supplier__contacts-list" }, [
                            _vm.supplier.web
                              ? _c(
                                  "li",
                                  { staticClass: "supplier__contacts-item" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "supplier__contacts-link",
                                        attrs: {
                                          href: _vm._f("urlAddProtocol")(
                                            _vm.supplier.web
                                          ),
                                          target: "_blank",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$ga.event(
                                              "contacts",
                                              "homepage click"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "supplier__contacts-icon",
                                          domProps: {
                                            innerHTML: _vm._s(_vm.svg.siteIcon),
                                          },
                                        }),
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm._f("urlHideProtocol")(
                                                _vm.supplier.web
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.supplier.email
                              ? _c(
                                  "li",
                                  { staticClass: "supplier__contacts-item" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "supplier__contacts-link",
                                        attrs: {
                                          href: "mailto:" + _vm.supplier.email,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$ga.event(
                                              "contacts",
                                              "email click"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "supplier__contacts-icon",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.svg.emailIcon
                                            ),
                                          },
                                        }),
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.supplier.email) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.supplier.phone
                              ? _c(
                                  "li",
                                  { staticClass: "supplier__contacts-item" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "supplier__contacts-link",
                                        attrs: {
                                          href: "tel:" + _vm.supplier.phone,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$ga.event(
                                              "contacts",
                                              "phone click"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "supplier__contacts-icon",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.svg.phoneIcon
                                            ),
                                          },
                                        }),
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.supplier.phone) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.supplier.whatsApp
                              ? _c(
                                  "li",
                                  { staticClass: "supplier__contacts-item" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "supplier__contacts-link",
                                        on: {
                                          click: function ($event) {
                                            return _vm.$ga.event(
                                              "contacts",
                                              "WhatsApp click"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "supplier__contacts-icon",
                                          domProps: {
                                            innerHTML: _vm._s(_vm.svg.whatsApp),
                                          },
                                        }),
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.supplier.whatsApp) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.supplier.weChat
                              ? _c(
                                  "li",
                                  { staticClass: "supplier__contacts-item" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "supplier__contacts-link",
                                        on: {
                                          click: function ($event) {
                                            return _vm.$ga.event(
                                              "contacts",
                                              "WeChat click"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "supplier__contacts-icon",
                                          domProps: {
                                            innerHTML: _vm._s(_vm.svg.weChat),
                                          },
                                        }),
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.supplier.weChat) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.supplier.skype
                              ? _c(
                                  "li",
                                  { staticClass: "supplier__contacts-item" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "supplier__contacts-link",
                                        on: {
                                          click: function ($event) {
                                            return _vm.$ga.event(
                                              "contacts",
                                              "Skype click"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "supplier__contacts-icon",
                                          domProps: {
                                            innerHTML: _vm._s(_vm.svg.skype),
                                          },
                                        }),
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.supplier.skype) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _vm.social
                            ? _c("ul", { staticClass: "supplier__social" }, [
                                _vm.supplier.linkedIn
                                  ? _c(
                                      "li",
                                      { staticClass: "supplier__social-item" },
                                      [
                                        _c("a", {
                                          staticClass: "supplier__social-link",
                                          attrs: {
                                            href: _vm._f("urlAddProtocol")(
                                              _vm.supplier.linkedIn
                                            ),
                                            target: "_blank",
                                          },
                                          domProps: {
                                            innerHTML: _vm._s(_vm.svg.linkedIn),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$ga.event(
                                                "contacts",
                                                "linkedIn click"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.supplier.facebook
                                  ? _c(
                                      "li",
                                      { staticClass: "supplier__social-item" },
                                      [
                                        _c("a", {
                                          staticClass: "supplier__social-link",
                                          attrs: {
                                            href: _vm._f("urlAddProtocol")(
                                              _vm.supplier.facebook
                                            ),
                                            target: "_blank",
                                          },
                                          domProps: {
                                            innerHTML: _vm._s(_vm.svg.facebook),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$ga.event(
                                                "contacts",
                                                "facebook click"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.supplier.instagram
                                  ? _c(
                                      "li",
                                      { staticClass: "supplier__social-item" },
                                      [
                                        _c("a", {
                                          staticClass: "supplier__social-link",
                                          attrs: {
                                            href: _vm._f("urlAddProtocol")(
                                              _vm.supplier.instagram
                                            ),
                                            target: "_blank",
                                          },
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.svg.instagram
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$ga.event(
                                                "contacts",
                                                "instagram click"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.supplier.pinterest
                                  ? _c(
                                      "li",
                                      { staticClass: "supplier__social-item" },
                                      [
                                        _c("a", {
                                          staticClass: "supplier__social-link",
                                          attrs: {
                                            href: _vm._f("urlAddProtocol")(
                                              _vm.supplier.pinterest
                                            ),
                                            target: "_blank",
                                          },
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.svg.pinterest
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$ga.event(
                                                "contacts",
                                                "pinterest click"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.supplier.twitter
                                  ? _c(
                                      "li",
                                      { staticClass: "supplier__social-item" },
                                      [
                                        _c("a", {
                                          staticClass: "supplier__social-link",
                                          attrs: {
                                            href: _vm._f("urlAddProtocol")(
                                              _vm.supplier.twitter
                                            ),
                                            target: "_blank",
                                          },
                                          domProps: {
                                            innerHTML: _vm._s(_vm.svg.twitter),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$ga.event(
                                                "contacts",
                                                "twitter click"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.supplier.googlePlus
                                  ? _c(
                                      "li",
                                      { staticClass: "supplier__social-item" },
                                      [
                                        _c("a", {
                                          staticClass: "supplier__social-link",
                                          attrs: {
                                            href: _vm._f("urlAddProtocol")(
                                              _vm.supplier.googlePlus
                                            ),
                                            target: "_blank",
                                          },
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.svg.googlePlus
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$ga.event(
                                                "contacts",
                                                "google+ click"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.supplier.youTube
                                  ? _c(
                                      "li",
                                      { staticClass: "supplier__social-item" },
                                      [
                                        _c("a", {
                                          staticClass: "supplier__social-link",
                                          attrs: {
                                            href: _vm._f("urlAddProtocol")(
                                              _vm.supplier.youTube
                                            ),
                                            target: "_blank",
                                          },
                                          domProps: {
                                            innerHTML: _vm._s(_vm.svg.youtube),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$ga.event(
                                                "contacts",
                                                "youtube click"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.apiccapsId
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "supplier__association supplier__association--appicaps",
                                  attrs: {
                                    href: "https://www.apiccaps.pt",
                                    target: "_blank",
                                    rel: "noopener noreferrer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$ga.event(
                                        "partners",
                                        "APICCAPS click - supplier page"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "supplier__association-image",
                                    attrs: {
                                      src: require("../../img/partner-logos/apiccaps.png"),
                                      alt: "APICCAPS member",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.ciceg
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "supplier__association supplier__association--ciceg",
                                  attrs: {
                                    href: "https://www.ciceg.org",
                                    target: "_blank",
                                    rel: "noopener noreferrer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$ga.event(
                                        "partners",
                                        "CICEG click - supplier page"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "supplier__association-image",
                                    attrs: {
                                      src: require("../../img/partner-logos/ciceg.png"),
                                      alt: "CICEG member",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("h2", { attrs: { id: "production" } }, [
                      _vm._v("Production"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "supplier-content__data" }, [
                      _vm.supplier.companyStructure !== null
                        ? _c(
                            "div",
                            { staticClass: "supplier-content__data-label" },
                            [
                              _vm._v(
                                "\n              Company Structure\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.supplier.companyStructure !== null
                        ? _c(
                            "div",
                            { staticClass: "supplier-content__data-content" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.supplier.companyStructure == 0
                                      ? "Manufacturer"
                                      : "Agent"
                                  ) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.supplier.category !== null
                        ? _c(
                            "div",
                            { staticClass: "supplier-content__data-label" },
                            [_vm._v("Producing")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.supplier.category !== null
                        ? _c(
                            "div",
                            { staticClass: "supplier-content__data-content" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.supplier.category == 0
                                      ? "Footwear"
                                      : "Components"
                                  ) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm.supplier.category == 0
                      ? _c("div", { staticClass: "supplier-content__data" }, [
                          _vm.supplier.footwearTypes &&
                          _vm.supplier.footwearTypes.length
                            ? _c(
                                "div",
                                { staticClass: "supplier-content__data-label" },
                                [
                                  _vm._v(
                                    "\n              Footwear Types\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.footwearTypes &&
                          _vm.supplier.footwearTypes.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "supplier-content__data-content",
                                },
                                _vm._l(
                                  _vm.supplier.footwearTypes,
                                  function (article, i) {
                                    return _c(
                                      "span",
                                      { key: i },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "article",
                                                params: {
                                                  category:
                                                    article.categorySlug,
                                                  slug: article.slug,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(article.title) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        i + 1 <
                                        _vm.supplier.footwearTypes.length
                                          ? _c("span", [_vm._v(", ")])
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.processes &&
                          _vm.supplier.processes.length
                            ? _c(
                                "div",
                                { staticClass: "supplier-content__data-label" },
                                [
                                  _vm._v(
                                    "\n              Process\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.processes &&
                          _vm.supplier.processes.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "supplier-content__data-content",
                                },
                                _vm._l(
                                  _vm.supplier.processes,
                                  function (article, i) {
                                    return _c(
                                      "span",
                                      { key: i },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "article",
                                                params: {
                                                  category:
                                                    article.categorySlug,
                                                  slug: article.slug,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(article.title) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        i + 1 < _vm.supplier.processes.length
                                          ? _c("span", [_vm._v(", ")])
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.materials &&
                          _vm.supplier.materials.length
                            ? _c(
                                "div",
                                { staticClass: "supplier-content__data-label" },
                                [
                                  _vm._v(
                                    "\n              Materials\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.materials &&
                          _vm.supplier.materials.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "supplier-content__data-content",
                                },
                                _vm._l(
                                  _vm.supplier.materials,
                                  function (article, i) {
                                    return _c(
                                      "span",
                                      { key: i },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "article",
                                                params: {
                                                  category:
                                                    article.categorySlug,
                                                  slug: article.slug,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(article.title) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        i + 1 < _vm.supplier.materials.length
                                          ? _c("span", [_vm._v(", ")])
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.gender.length
                            ? _c(
                                "div",
                                { staticClass: "supplier-content__data-label" },
                                [_vm._v("Gender")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.gender.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "supplier-content__data-content",
                                },
                                [_vm._v(_vm._s(_vm.gender))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.lastingMethods &&
                          _vm.supplier.lastingMethods.length
                            ? _c(
                                "div",
                                { staticClass: "supplier-content__data-label" },
                                [
                                  _vm._v(
                                    "\n              Lasting Method\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.lastingMethods &&
                          _vm.supplier.lastingMethods.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "supplier-content__data-content",
                                },
                                _vm._l(
                                  _vm.supplier.lastingMethods,
                                  function (item, i) {
                                    return _c("span", { key: i }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.title) +
                                          "\n                "
                                      ),
                                      i + 1 < _vm.supplier.lastingMethods.length
                                        ? _c("span", [_vm._v(", ")])
                                        : _vm._e(),
                                    ])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.niche && _vm.supplier.niche.length
                            ? _c(
                                "div",
                                { staticClass: "supplier-content__data-label" },
                                [_vm._v("Niche")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.niche && _vm.supplier.niche.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "supplier-content__data-content",
                                },
                                _vm._l(_vm.supplier.niche, function (item, i) {
                                  return _c("span", { key: i }, [
                                    _vm._v(
                                      "\n                " + _vm._s(item.title)
                                    ),
                                    i + 1 < _vm.supplier.niche.length
                                      ? _c("span", [_vm._v(", ")])
                                      : _vm._e(),
                                  ])
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.capabilities &&
                          _vm.supplier.capabilities.length
                            ? _c(
                                "div",
                                { staticClass: "supplier-content__data-label" },
                                [
                                  _vm._v(
                                    "\n              Capabilities\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.capabilities &&
                          _vm.supplier.capabilities.length
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "supplier-content__data-content supplier__grid supplier__grid",
                                },
                                _vm._l(
                                  _vm.supplier.capabilities,
                                  function (item, i) {
                                    return i == 0 ||
                                      item.category !=
                                        _vm.supplier.capabilities[i - 1]
                                          .category
                                      ? _c(
                                          "div",
                                          {
                                            key: i,
                                            staticClass: "supplier__grid-group",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "supplier__grid-group-label",
                                              },
                                              [_vm._v(_vm._s(item.category))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "supplier__grid-group-list",
                                              },
                                              _vm._l(
                                                _vm.supplier.capabilities.filter(
                                                  function (i) {
                                                    return (
                                                      i.category ==
                                                      item.category
                                                    )
                                                  }
                                                ),
                                                function (item, i) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: i,
                                                      staticClass:
                                                        "supplier__grid-item supplier__grid-item--large",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(item.title) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        )
                                      : _vm._e()
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.supplier.category == 1 && _vm.showTypeOfProduction
                      ? _c("div", { staticClass: "supplier-content__data" }, [
                          _c(
                            "div",
                            { staticClass: "supplier-content__data-label" },
                            [_vm._v("Type of production")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "supplier-content__data-content" },
                            _vm._l(
                              _vm.$store.suppliers.typeOfProduction,
                              function (item, i) {
                                return item.options.some(function (el) {
                                  return _vm.supplier[el.key]
                                })
                                  ? _c(
                                      "div",
                                      { key: i, staticClass: "cstop" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "cstop__label" },
                                          [_vm._v(_vm._s(item.title))]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          item.options,
                                          function (category, i) {
                                            return _vm.supplier[category.key]
                                              ? _c(
                                                  "div",
                                                  {
                                                    key: i,
                                                    staticClass: "cstop__item",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(category.title)
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "cstop__grid",
                                                      },
                                                      _vm._l(
                                                        category.options,
                                                        function (group, i) {
                                                          return group.options.some(
                                                            function (el) {
                                                              return _vm
                                                                .supplier[
                                                                el.key
                                                              ]
                                                            }
                                                          )
                                                            ? _c(
                                                                "div",
                                                                { key: i },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "cstop__label",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          group.groupTitle
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "supplier__grid-group-list",
                                                                    },
                                                                    _vm._l(
                                                                      group.options,
                                                                      function (
                                                                        option,
                                                                        i
                                                                      ) {
                                                                        return _vm
                                                                          .supplier[
                                                                          option
                                                                            .key
                                                                        ]
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                key: i,
                                                                                class:
                                                                                  "supplier__grid-item supplier__grid-item--" +
                                                                                  group.gridSize,
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        option.title
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e()
                                                                      }
                                                                    ),
                                                                    0
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                )
                                              : _vm._e()
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.companyInfoEmpty
                      ? _c("h2", { attrs: { id: "info" } }, [
                          _vm._v("Company information"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.companyInfoEmpty
                      ? _c("div", { staticClass: "supplier-content__data" }, [
                          _vm.supplier.founded
                            ? _c(
                                "div",
                                { staticClass: "supplier-content__data-label" },
                                [_vm._v("Founded")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.founded
                            ? _c(
                                "div",
                                {
                                  staticClass: "supplier-content__data-content",
                                },
                                [_vm._v(_vm._s(_vm.supplier.founded))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.employees
                            ? _c(
                                "div",
                                { staticClass: "supplier-content__data-label" },
                                [_vm._v("Employees")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.employees
                            ? _c(
                                "div",
                                {
                                  staticClass: "supplier-content__data-content",
                                },
                                [_vm._v(_vm._s(_vm.supplier.employees))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.moq
                            ? _c(
                                "div",
                                { staticClass: "supplier-content__data-label" },
                                [_vm._v("Minimum Order Quantity")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.moq
                            ? _c(
                                "div",
                                {
                                  staticClass: "supplier-content__data-content",
                                },
                                [_vm._v(_vm._s(_vm.supplier.moq))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.dailyProductionFrom &&
                          _vm.supplier.dailyProductionTo
                            ? _c(
                                "div",
                                { staticClass: "supplier-content__data-label" },
                                [
                                  _vm._v(
                                    "\n              Daily Production\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.dailyProductionFrom &&
                          _vm.supplier.dailyProductionTo
                            ? _c(
                                "div",
                                {
                                  staticClass: "supplier-content__data-content",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.supplier.dailyProductionFrom) +
                                      " - " +
                                      _vm._s(_vm.supplier.dailyProductionTo) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.brands
                            ? _c(
                                "div",
                                { staticClass: "supplier-content__data-label" },
                                [_vm._v("Brands producing")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.brands
                            ? _c(
                                "div",
                                {
                                  staticClass: "supplier-content__data-content",
                                },
                                [_vm._v(_vm._s(_vm.supplier.brands))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.materialExpertise.length
                            ? _c(
                                "div",
                                { staticClass: "supplier-content__data-label" },
                                [_vm._v("Material Expertise")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.materialExpertise.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "supplier-content__data-content",
                                },
                                [_vm._v(_vm._s(_vm.materialExpertise))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.sizeRangeFrom &&
                          _vm.supplier.sizeRangeFrom
                            ? _c(
                                "div",
                                { staticClass: "supplier-content__data-label" },
                                [
                                  _vm._v(
                                    "\n              Size Range producing\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.supplier.sizeRangeFrom &&
                          _vm.supplier.sizeRangeFrom
                            ? _c(
                                "div",
                                {
                                  staticClass: "supplier-content__data-content",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.supplier.sizeRangeFrom) +
                                      " – " +
                                      _vm._s(_vm.supplier.sizeRangeTo) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.customers.length
                      ? _c("h2", { attrs: { id: "customers" } }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.supplier.category == 0
                                  ? "Component suppliers working with"
                                  : "Footwear suppliers working with"
                              ) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.customers.length
                      ? _c(
                          "ul",
                          { staticClass: "supplier__customers" },
                          _vm._l(_vm.customers, function (customer, i) {
                            return _c(
                              "li",
                              { key: i, staticClass: "supplier__customer" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "supplier__customer-link",
                                    attrs: {
                                      to: {
                                        name: "supplier",
                                        params: { name: customer.slug },
                                      },
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "supplier__customer-img",
                                      style: {
                                        backgroundImage:
                                          "url('" +
                                          (_vm.imagePath(
                                            "images/" + customer.coverPhoto
                                          ),
                                          1920,
                                          500) +
                                          ")",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "supplier__customer-info",
                                      },
                                      [
                                        _c(
                                          "strong",
                                          {
                                            staticClass:
                                              "supplier__customer-title",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(customer.name) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.supplier.gallery.length
                      ? _c(
                          "div",
                          { staticClass: "supplier__gallery" },
                          [
                            _c("h2", { attrs: { id: "gallery" } }, [
                              _vm._v("Gallery"),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.supplier.gallery, function (item, i) {
                              return _c(
                                "div",
                                {
                                  key: i,
                                  staticClass: "supplier__gallery-item",
                                },
                                [
                                  item.type == "img"
                                    ? _c("img", {
                                        staticClass: "supplier__gallery-img",
                                        attrs: {
                                          src:
                                            _vm.$blobUrl +
                                            "/images/" +
                                            item.url,
                                          alt: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.type == "video"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "supplier__gallery-video",
                                        },
                                        [
                                          _c("iframe", {
                                            attrs: {
                                              src: item.url,
                                              frameborder: "0",
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.caption.length
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "supplier__gallery-caption",
                                        },
                                        [_vm._v(_vm._s(item.caption))]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.showEditSupplierButton
                    ? _c(
                        "router-link",
                        {
                          staticClass: "supplier-user-button",
                          attrs: {
                            to: {
                              name: "supplierEdit",
                              params: { id: _vm.supplier.id },
                            },
                          },
                        },
                        [_vm._v("\n          Edit supplier\n        ")]
                      )
                    : _c(
                        "a",
                        {
                          staticClass: "supplier-user-button",
                          attrs: { href: "mailto:" + _vm.supplier.email },
                        },
                        [_vm._v(" Contact supplier ")]
                      ),
                  _vm._v(" "),
                  _vm.showClaimSupplierButton
                    ? _c(
                        "span",
                        {
                          staticClass: "supplier-user-button",
                          on: { click: _vm.claimSupplier },
                        },
                        [_vm._v("Claim Supplier")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ])
        : _c("div", { staticClass: "post-content" }, [
            _c("p", [_vm._v("Page not found")]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }